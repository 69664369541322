import React, { useEffect, useState } from 'react';

export default function Devices() {
    const [androidDevices, setAndroidDevices] = useState([]);
    const [iosDevices, setIosDevices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch('https://testlab.ttu.ee/devices/')
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                setAndroidDevices(data.androidDevices);
                setIosDevices(data.iosDevices);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <p>Loading devices...</p>;
    }

    if (error) {
        return <p>Error loading devices: {error.message}</p>;
    }

    return (
        <div>
            <h2>Android Devices ({androidDevices.length})</h2>
            <table>
                <thead>
                <tr>
                    <th>Brand</th>
                    <th>Model</th>
                    <th>ID</th>
                    <th>API Level</th>
                </tr>
                </thead>
                <tbody>
                {androidDevices.map((device) => (
                    <tr key={device.deviceId}>
                        <td>{device.brand.charAt(0).toUpperCase() + device.brand.slice(1)}</td>
                        <td>{device.model}</td>
                        <td>{device.productName}</td>
                        <td>{device.apiLevel}</td>
                    </tr>
                ))}
                </tbody>
            </table>

            <h2>iOS Devices ({iosDevices.length})</h2>
            <table>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>API Level</th>
                    <th>UUID</th>
                    <th>Device Class</th>
                </tr>
                </thead>
                <tbody>
                {iosDevices.map((device) => (
                    <tr key={device.uuid}>
                        <td>{device.name}</td>
                        <td>{device.apiLevel}</td>
                        <td>{device.uuid}</td>
                        <td>{device.deviceClass}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}
